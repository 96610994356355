import React from 'react';
import { Link } from 'react-router-dom';
import LogoLPM from '../../Static/Img/Others/lpm/Logo.png';

const LpmMain = ({ language }) => {
  return (
    <div className="lpm-container">
      <div className="row">
        <div className="d-flex justify-content-center">
          <img src={LogoLPM} className="img-70" alt="Logo LPM" />
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-center mb-5">
          <h3>¿Que quieres hacer?</h3>
        </div>
        <ul className="text-center">
          <li><Link to={`/${language}/lpm/retoque_de_fotos`}>Retocar Imágenes</Link></li>
        </ul>
      </div>
    </div>
  )
}

export default LpmMain