import React, { useState, useEffect } from 'react';
import './Battleship.css';

const GRID_SIZE = 10;
const SHIPS = [5, 4, 3, 3, 2];

// 📌 Función para crear un tablero vacío
const createEmptyGrid = () => {
  return Array(GRID_SIZE).fill(null).map(() => Array(GRID_SIZE).fill(null));
};

// 📌 Función para verificar si un barco puede colocarse en una posición
const canPlaceShip = (grid, x, y, size, direction) => {
  for (let i = 0; i < size; i++) {
    const checkX = direction === 'horizontal' ? x : x + i;
    const checkY = direction === 'horizontal' ? y + i : y;
    if (checkX >= GRID_SIZE || checkY >= GRID_SIZE || grid[checkX][checkY] === 'S') {
      return false;
    }
  }
  return true;
};

// 📌 Función para colocar barcos aleatoriamente en el tablero
const placeShips = (grid) => {
  const newGrid = [...grid.map(row => [...row])]; // Clonar matriz
  SHIPS.forEach((shipSize) => {
    let placed = false;
    while (!placed) {
      const direction = Math.random() > 0.5 ? 'horizontal' : 'vertical';
      const startX = Math.floor(Math.random() * GRID_SIZE);
      const startY = Math.floor(Math.random() * GRID_SIZE);

      if (canPlaceShip(newGrid, startX, startY, shipSize, direction)) {
        for (let i = 0; i < shipSize; i++) {
          if (direction === 'horizontal') {
            newGrid[startX][startY + i] = 'S';
          } else {
            newGrid[startX + i][startY] = 'S';
          }
        }
        placed = true;
      }
    }
  });
  return newGrid;
};

const Battleship = () => {
  const [playerGrid, setPlayerGrid] = useState([]);
  const [systemGrid, setSystemGrid] = useState([]);
  const [playerShots, setPlayerShots] = useState([]);
  const [systemShots, setSystemShots] = useState([]);
  const [playerHits, setPlayerHits] = useState(0);
  const [systemHits, setSystemHits] = useState(0);
  const [turn, setTurn] = useState('player');
  const [message, setMessage] = useState('Dispara en el tablero del sistema.');
  const [newX, setNewX] = useState(null); // 📌 Se evita el warning creando estados
  const [newY, setNewY] = useState(null);

  // 📌 Inicializar tableros con barcos (placeShips ahora está fuera del componente)
  useEffect(() => {
    setPlayerGrid(placeShips(createEmptyGrid()));
    setSystemGrid(placeShips(createEmptyGrid()));
  }, []);

  // 📌 Manejo de disparos del jugador
  const handlePlayerShoot = (x, y) => {
    if (turn !== 'player' || playerShots.some(([sx, sy]) => sx === x && sy === y)) {
      return;
    }

    const newShots = [...playerShots, [x, y]];
    const isHit = systemGrid[x][y] === 'S';

    setPlayerShots(newShots);
    setPlayerHits(prev => prev + (isHit ? 1 : 0));
    setMessage(isHit ? '¡Impacto! Sigue disparando.' : 'Agua. Turno del sistema.');

    if (!isHit) setTurn('system');
  };

  // 📌 Turno del sistema (corregido para evitar warning de dependencias)
  useEffect(() => {
    if (turn === 'system') {
      const timer = setTimeout(() => {
        let validShot = false;

        while (!validShot) {
          const x = Math.floor(Math.random() * GRID_SIZE);
          const y = Math.floor(Math.random() * GRID_SIZE);
          validShot = !systemShots.some(([sx, sy]) => sx === x && sy === y);
          if (validShot) {
            setNewX(x);
            setNewY(y);
          }
        }

        const newShots = [...systemShots, [newX, newY]];
        const isHit = playerGrid[newX]?.[newY] === 'S';

        setSystemShots(newShots);
        setSystemHits(prev => prev + (isHit ? 1 : 0));
        setMessage(isHit ? 'El sistema te impactó.' : 'El sistema falló. Es tu turno.');
        setTurn('player');
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [turn, systemShots, playerGrid, newX, newY]);

  // 📌 Verificar si hay un ganador
  useEffect(() => {
    const totalShipCells = SHIPS.reduce((a, b) => a + b, 0);

    if (playerHits >= totalShipCells) {
      setMessage('¡Ganaste! Hundiste toda la flota enemiga.');
      setTurn(null);
    } else if (systemHits >= totalShipCells) {
      setMessage('¡Perdiste! El sistema hundió toda tu flota.');
      setTurn(null);
    }
  }, [playerHits, systemHits]);

  // 📌 Renderizar tablero
  const renderGrid = (grid, shots, isPlayerGrid) =>
    grid.map((row, x) => (
      <div key={x} className="row">
        {row.map((cell, y) => {
          const isShot = shots.some(([sx, sy]) => sx === x && sy === y);
          const isHit = isShot && grid[x][y] === 'S';
          const display = isPlayerGrid ? cell : isShot ? (isHit ? '💥' : '🌊') : '';
          return (
            <div
              key={y}
              className={`cell ${isHit ? 'hit' : isShot ? 'miss' : ''}`}
              onClick={() => !isPlayerGrid && handlePlayerShoot(x, y)}
            >
              {isPlayerGrid ? (cell === 'S' ? '🚢' : '') : display}
            </div>
          );
        })}
      </div>
    ));

  return (
    <div className="Battleship-container">
      <h1>Battleship</h1>
      <p>{message}</p>
      <div className="grids">
        <div className="me-3">
          <h2>Usuario</h2>
          {renderGrid(playerGrid, systemShots, true)}
        </div>
        <div className="ms-3">
          <h2>Sistema</h2>
          {renderGrid(systemGrid, playerShots, false)}
        </div>
      </div>
    </div>
  );
};

export default Battleship;
