import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import "./RetoqueFotos.css";

const RetoqueFotos = ({ language }) => {
  const [files, setFiles] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("info");
  const [showAlert, setShowAlert] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); // 📌 Estado para el modal y el botón

  // 📌 Manejo de Drag and Drop
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
  });

  // 📌 Manejo de cambio de email
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // 📌 Eliminar una imagen de la lista
  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  // 📌 Enviar imágenes al backend
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (files.length === 0 || email === "") {
      setMessage("⚠️ Debes subir al menos una imagen y escribir tu correo.");
      setAlertType("warning");
      setShowAlert(true);
      return;
    }

    setIsProcessing(true); // 📌 Mostrar modal de carga

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("images", file);
    });
    formData.append("email", email);

    try {
      const response = await fetch("https://api.santamariai.cl/api/process-and-send", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        setMessage("✅ Imágenes enviadas correctamente. Revisa tu correo.");
        setAlertType("success");
        setFiles([]); // Limpiar archivos después del envío
        setEmail(""); // Limpiar correo
      } else {
        setMessage("❌ Hubo un error al enviar las imágenes.");
        setAlertType("danger");
      }
    } catch (error) {
      setMessage("❌ Error de conexión con el servidor.");
      setAlertType("danger");
    }

    setIsProcessing(false); // 📌 Ocultar modal de carga
    setShowAlert(true);
  };

  // 📌 Cerrar alerta automáticamente después de 5 segundos
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  return (
    <div className="retoque-container">
      {/* 📌 Alertas Dinámicas con Bootstrap */}
      {showAlert && (
        <div className={`alert alert-${alertType} alert-dismissible fade show text-center fixed-top`} role="alert">
          {message}
          <button type="button" className="btn-close" onClick={() => setShowAlert(false)}></button>
        </div>
      )}

      {/* 📌 Modal de Carga */}
      {isProcessing && (
        <div className="modal fade show d-block" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-center p-4">
              <h5>📤 Procesando imágenes...</h5>
              <div className="spinner-border text-primary my-3" role="status">
                <span className="visually-hidden">Cargando...</span>
              </div>
              <p>Por favor, espera mientras procesamos tus imágenes.</p>
            </div>
          </div>
        </div>
      )}

      <div className="row d-flex justify-content-center my-5">
        <div className="col-12 col-md-6">
          <h2 className="text-center mb-5">📸 Retoque de Fotos LPM</h2>
          <p>Sube tus imágenes y recibirás las versiones retocadas en tu correo electrónico.</p>
        </div>
      </div>

      <div className="row p-5">
        {/* 📌 Sección Drag and Drop */}
        <div className="col-12 col-md-6 p-3">
          <div className="d-flex justify-content-center mb-3">
            <p className="me-2">Correo Electrónico</p>
            <input className="form-control" type="email" value={email} onChange={handleEmailChange} required />
          </div>

          <div {...getRootProps()} className="dropzone p-5">
            <input {...getInputProps()} />
            <p>📤 Arrastra y suelta imágenes aquí, o haz clic para seleccionar archivos</p>
          </div>
        </div>

        {/* 📌 Vista previa de imágenes */}
        <div className="col-12 col-md-6 p-3">
          <h4>Vista previa</h4>
          {files.length > 0 ? (
            <div className="preview-container">
              {
                files.map((file, index) => (
                  <div key={index} className="image-preview">
                    <img src={URL.createObjectURL(file)} alt={`preview-${index}`} />
                    <button className="btn btn-danger" onClick={() => handleRemoveFile(index)}>X</button>
                  </div>
                ))
              }
            </div>
          ) : (
            <p>No hay imágenes seleccionadas</p>
          )}
        </div>
      </div>

      {/* 📌 Botones de acción */}
      <div className="row my-4">
        <div className="col d-flex justify-content-center">
          <Link to={`/${language}/lpm`} className="btn btn-secondary me-3">⬅ Volver</Link>
          <button className="btn btn-primary" onClick={handleSubmit} disabled={isProcessing}>
            📩 Enviar Imágenes
          </button>
        </div>
      </div>
    </div>
  );
};

export default RetoqueFotos;
