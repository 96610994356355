import React from "react";
import { Link } from "react-router-dom";
import DirectResume from "../Components/DirectResume";
import Battleship from "../Views/Portafolio/Battleship/Battleship";

const EnConstruccion = ({ language, info }) => {
  const { title, message, buttonText } = info.enConstruccion;

  return (
    <div className="enConstruccion-container text-center">
      <div className="row">
        <div className="col-12 col-md-4">
          <h2 className="my-5 p-5"><strong>{title}</strong></h2>
          <h5 className="my-5 p-5">{message}</h5>
          <div className="my-5">
            <Link className="new-btn-secondary" to="/">
              {buttonText} &gt;
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-8 my-5">
          <Battleship />
        </div>
        <div className="my-2">
          <DirectResume language={language} info={info.home.resumeShortcut} />
        </div>
      </div>
    </div>
  );
};

export default EnConstruccion;
